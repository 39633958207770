<template>
  <div class="home">
    <v-container fluid>
      <UserList />
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import UserList from "@/components/UserList.vue";

export default {
  name: "Home",
  components: {
    UserList,
  },
};
</script>
