














import { Component, Vue, Emit } from "vue-property-decorator";
import { Group, User } from "../api/types";
import { userModule } from "../store/modules/UserModule";
import GroupView from "./GroupView.vue";

@Component({
  components: {
    GroupView,
  },
})
export default class GroupDetail extends Vue {
  get user(): User {
    return userModule.currentUser;
  }

  get group(): Group {
    return (
      userModule.detailGroup || {
        groupKey: "",
        label: "情報が見つかりませんでした",
      }
    );
  }

  @Emit()
  edit(groupKey: string) {
    userModule.startEdit(groupKey);
  }
}
