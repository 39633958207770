























































import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { emptyUser, User } from "../api/types";

interface ValidatableForm extends Element {
  validate: () => boolean;
  reset: () => void;
  resetValidation: () => void;
}

@Component
export default class UserEditView extends Vue {
  @Prop() error!: string;

  @Prop() editUser!: User | null;

  private user = emptyUser();

  private inputUserId = 1;

  private valid = false;

  private rules = {
    required: (value: string) => !!value || "入力してください",
    userId: (value: string) =>
      /^[0-9]{7}$/.test(value) || "学徒番号を入力してください",
    phonetic: (value: string) =>
      /^[ぁ-んー\x20\u3000]*$/.test(value) || "ひらがなで入力してください",
    branch: (value: string) =>
      /^.*支部$/.test(value) || "「○○支部」という形式で入力してください",
  };

  get canChangeInputUserId() {
    return !this.editUser || this.editUser.userId.startsWith("E");
  }

  clear() {
    this.user = emptyUser();
  }

  mounted() {
    this.applyEditUser(this.editUser);
  }

  @Watch("editUser")
  watchEditUser(value: User | null) {
    this.applyEditUser(value);
  }

  private applyEditUser(value: User | null) {
    if (value) {
      this.user = Object.assign({}, value);
      if (value.userId.startsWith("E")) {
        this.inputUserId = 0;
        this.user.userId = "";
      } else {
        this.inputUserId = 1;
      }
    } else {
      this.clear();
    }
    (this.$refs.form as ValidatableForm).resetValidation();
  }

  @Emit()
  save(): User {
    if (this.inputUserId == 0) {
      this.user.userId = this.editUser ? this.editUser.userId : "";
    }
    return this.user;
  }

  @Emit()
  cancel() {}
}
