import BaseApi from "@/api/BaseApi";
import { User } from "./types";

class UserListApi extends BaseApi<{ list: User[] }> {
  constructor() {
    super("GET", "userlist");
  }

  public async call(): Promise<User[]> {
    return this.callInternal().then((result) => result.list);
  }
}

export default new UserListApi();
