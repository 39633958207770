import BaseApi from "@/api/BaseApi";
import { User } from "./types";

class UserAddApi extends BaseApi<void> {
  constructor() {
    super("POST", "user");
  }

  public async call(user: User, oldUserId = ""): Promise<void> {
    return this.callInternal({ data: { user, oldUserId } });
  }
}

export default new UserAddApi();
