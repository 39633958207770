































































import { Component, Prop, Mixins, Emit } from "vue-property-decorator";
import { ItemSet, Item, SetItem } from "../api/types";
import ItemBaseView from "./ItemBaseView";
import { clone } from "@/common/utility";

@Component({
  components: {
    ItemView: () => import("./ItemView.vue"),
  },
})
export default class ItemSetView extends Mixins(ItemBaseView) {
  @Prop() value!: ItemSet;

  get canSort(): boolean {
    return this.value.sortKey == "manual";
  }

  get isDesc(): boolean {
    return this.value.sortKey.includes("desc");
  }

  get childList(): SetItem[] {
    const list = clone(this.value.value).filter((item) =>
      item.data.some((child) => !child.deleted)
    );
    if (this.isDesc) {
      list.sort((a, b) => b.index - a.index);
    } else {
      list.sort((a, b) => a.index - b.index);
    }
    return list;
  }

  @Emit("input")
  childChanged(childSetIndex: number, index: number, child: Item): ItemSet {
    const copied = this.copyValue() as ItemSet;
    for (let i = 0; i < copied.value.length; i++) {
      if (copied.value[i].index == childSetIndex) {
        copied.value[i].data[index] = child;
        break;
      }
    }
    return copied;
  }

  @Emit("input")
  addChild(): ItemSet {
    const copied = this.copyValue() as ItemSet;
    const index =
      this.childList.length == 0
        ? 1
        : this.childList[this.isDesc ? 0 : this.childList.length - 1].index + 1;
    const template = clone(this.value.template);
    copied.value.push({
      index,
      data: template,
    });
    return copied;
  }

  @Emit("input")
  deleteChild(childSetIndex: number): ItemSet {
    const copied = this.copyValue() as ItemSet;
    copied.value
      .find((item) => item.index == childSetIndex)
      ?.data.forEach((item) => (item.deleted = true));
    return copied;
  }

  @Emit("input")
  swap(childSetIndex: number): ItemSet {
    const copied = this.copyValue() as ItemSet;
    for (let i = 0; i < copied.value.length; i++) {
      if (copied.value[i].index == childSetIndex) {
        const temp = copied.value[i].index;
        copied.value[i].index = copied.value[i - 1].index;
        copied.value[i - 1].index = temp;
        break;
      }
    }
    return copied;
  }

  isWideItem(item: Item): boolean {
    return item.type == "itemset" || (item.type == "text" && item.size >= 40);
  }
}
