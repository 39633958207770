export type User = {
  userId: string;
  name: string;
  phonetic: string;
  branch: string;
  mainStaffId: string;
  mainStaffName: string;
  auth: "all" | "branch" | "area" | "manager";
  secondaryPoint: number;
  nextSecondaryPoint: number;
  secondaryPoint0: number;
  secondaryPoint1: number;
  secondaryPoint2: number;
  secondaryPoint3: number;
  kartePoint: number;
  nextKartePoint: number;
  kartePoint0: number;
  kartePoint1: number;
  kartePoint2: number;
  kartePoint3: number;
  excludeTarget: number;
};

export function emptyUser(): User {
  return {
    userId: "",
    name: "",
    phonetic: "",
    branch: "",
    mainStaffId: "",
    mainStaffName: "",
    auth: "all",
    secondaryPoint: 0,
    nextSecondaryPoint: 0,
    secondaryPoint0: 0,
    secondaryPoint1: 0,
    secondaryPoint2: 0,
    secondaryPoint3: 0,
    kartePoint: 0,
    nextKartePoint: 0,
    kartePoint0: 0,
    kartePoint1: 0,
    kartePoint2: 0,
    kartePoint3: 0,
    excludeTarget: 0,
  };
}

export type UserDelete = {
  userId: string;
  reason: string;
};

type ItemBase<T, V> = {
  itemKey: string;
  type: T;
  label: string;
  nameable: boolean;
  id: number;
  value: V;
  namedLabel: string;
  created: number;
  updated: number;
  editable: boolean;
  deleted: boolean;
};

export type ItemText = ItemBase<"text", string> & {
  size: number;
};

export type ItemRadio = ItemBase<"radio", string> & {
  selection: string[];
};

export type ItemCheckbox = ItemBase<"checkbox", boolean> & {
  selection: string[];
};

export type ItemDate = ItemBase<"date", number>;

export type ItemImage = ItemBase<"image", string>;

export type ItemAudio = ItemBase<"audio", string>;

export type ItemSet = ItemBase<"itemset", SetItem[]> & {
  sortKey: "created" | "updated" | "manual";
  template: Item[];
  next: string;
};

export type Item =
  | ItemText
  | ItemRadio
  | ItemCheckbox
  | ItemDate
  | ItemImage
  | ItemAudio
  | ItemSet;

export type SetItem = {
  index: number;
  data: Item[];
};

export type Group = {
  groupKey: string;
  label: string;
  hasDetail?: boolean;
  itemList?: Item[];
};

export type GroupData = Group & {
  itemList: Item[];
};

export type CategoryInfo = {
  categoryKey: string;
  label: string;
  groupList: Group[];
};
