import BaseApi from "@/api/BaseApi";

class LogoutApi extends BaseApi<void> {
  constructor() {
    super("POST", "logout");
  }

  public async call(): Promise<void> {
    return this.callInternal();
  }
}

export default new LogoutApi();
