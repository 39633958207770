import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Item } from "../api/types";
import ItemLabelView from "./ItemLabelView.vue";
import { clone } from "@/common/utility";

@Component({
  components: { ItemLabelView },
})
export default class ItemBaseView extends Vue {
  @Prop() value!: Item;

  @Prop() edit!: boolean;

  get inputValue(): Item["value"] {
    return this.value.value;
  }

  set inputValue(value: Item["value"]) {
    this.input(value);
  }

  get isNew(): boolean {
    return this.value.id == 0;
  }

  get isEdit() {
    return this.edit && this.value.editable;
  }

  @Emit()
  input(value: Item["value"]): Item {
    return this.copyValue({ value });
  }

  @Emit("input")
  inputLabel(namedLabel: string): Item {
    return this.copyValue({ namedLabel });
  }

  protected copyValue(params: Partial<Item> = {}): Item {
    return Object.assign(clone(this.value), params);
  }
}
