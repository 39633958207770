


















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Item } from "../api/types";

@Component
export default class ItemLabelView extends Vue {
  @Prop() item!: Item;

  @Prop() edit!: boolean;

  @Prop() wide?: boolean;

  get label(): string {
    return this.item.label;
  }

  get displayLabel(): string {
    return this.item.nameable ? this.item.namedLabel : this.item.label;
  }

  set displayLabel(value: string) {
    this.input(value);
  }

  get isEdit(): boolean {
    return this.edit && this.item.nameable;
  }

  @Emit()
  input(value: string): string {
    return value;
  }
}
