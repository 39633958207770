import axios, { Method, AxiosRequestConfig, AxiosError } from "axios";
import { ENDPOINT, logd } from "@/environment";

export default abstract class BaseApi<Response> {
  private method: Method;
  private path: string;
  private timeout: number;
  private useData: boolean;

  protected constructor(method: Method, path: string, timeout = 10000) {
    this.method = method;
    this.path = path;
    this.timeout = timeout;
    this.useData = ["PUT", "POST", "PATCH", "DELETE"].includes(
      this.method.toUpperCase()
    );
  }

  protected async callInternal(
    params: {
      pathParams?: string[];
      queries?: { [key: string]: string } | null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data?: any;
    } = {}
  ): Promise<Response> {
    let url = this.path;
    if (params.pathParams) {
      url += "/" + params.pathParams.join("/");
    }
    const config: AxiosRequestConfig = {
      url,
      method: this.method,
      baseURL: ENDPOINT + "api/",
      timeout: this.timeout,
      withCredentials: true,
    };
    if (params.queries) {
      config.params = params.queries;
    }
    if (this.useData && params.data) {
      config.data = params.data;
    }
    logd(() => "API called");
    logd(() => config);
    try {
      const response = await axios.request(config);
      logd(() => response);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  // eslint-disable-next-line
  public isApiError(e: any): e is AxiosError {
    return e.isAxiosError;
  }
}
