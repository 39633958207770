import BaseApi from "@/api/BaseApi";
import { Item } from "./types";

class GroupGetApi extends BaseApi<{ list: Item[] }> {
  constructor() {
    super("GET", "group");
  }

  public async call(
    userId: string,
    groupKey: string,
    all: boolean
  ): Promise<Item[]> {
    return this.callInternal({
      pathParams: [userId, groupKey],
      queries: { all: all ? "1" : "0" },
    }).then((result) => result.list);
  }
}

export default new GroupGetApi();
