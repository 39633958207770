













import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { userModule } from "../store/modules/UserModule";
import UserInfo from "@/components/UserInfo.vue";
import GroupDetail from "@/components/GroupDetail.vue";
import GroupEdit from "@/components/GroupEdit.vue";

@Component({
  components: {
    UserInfo,
    GroupDetail,
    GroupEdit,
  },
})
export default class User extends Vue {
  @Prop() userId!: string;

  @Prop() groupKey?: string;

  get currentUserId(): string {
    return userModule.currentUserId;
  }

  get existsUser(): boolean {
    return userModule.existsUser;
  }

  @Watch("userId", { immediate: true })
  watchUserId(userId: string) {
    if (userId != userModule.currentUserId) {
      userModule.setCurrentUserId(userId);
    }
    userModule.loadDetailGroup(this.groupKey || "");
  }

  @Watch("groupKey", { immediate: true })
  watchGroupKey(groupKey: string | undefined) {
    userModule.loadDetailGroup(groupKey || "");
  }

  @Watch("currentUserId")
  watchCurrentUserId(userId: string) {
    if (userId != this.userId) {
      this.$router.replace({ name: "User", params: { userId } });
    }
  }
}
