










import { Component, Prop, Mixins } from "vue-property-decorator";
import { ItemCheckbox } from "../api/types";
import ItemBaseView from "./ItemBaseView";

@Component
export default class ItemCheckboxView extends Mixins(ItemBaseView) {
  @Prop() value!: ItemCheckbox;

  get valueString(): string {
    return this.value.selection[this.inputValue ? 0 : 1];
  }
}
