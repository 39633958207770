

























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Group, Item } from "../api/types";
import ItemView from "./ItemView.vue";

@Component({
  components: {
    ItemView,
  },
})
export default class GroupView extends Vue {
  @Prop() group!: Group;

  @Prop() single?: string | undefined;

  get isSingle(): boolean {
    return this.single !== undefined;
  }

  get itemList(): Item[] {
    return this.group.itemList || [];
  }

  get hasItem(): boolean {
    return this.itemList.length > 0;
  }

  get isEditale(): boolean {
    return this.itemList.some((item) => this.isEdiableItem(item));
  }

  get hasDetail(): boolean {
    return !this.isSingle && !!this.group.hasDetail;
  }

  private isEdiableItem(item: Item): boolean {
    return (
      item.editable ||
      (item.type == "itemset" &&
        item.value.some((childSet) =>
          childSet.data.some((child) => this.isEdiableItem(child))
        ))
    );
  }

  @Emit()
  edit(): string {
    return this.group.groupKey;
  }

  @Emit()
  load(): string {
    return this.group.groupKey;
  }

  @Emit()
  detail(): string {
    return this.group.groupKey;
  }
}
