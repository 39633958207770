








import { Component, Vue } from "vue-property-decorator";
import { loginModule } from "../store/modules/LoginModule";

@Component
export default class Navigation extends Vue {
  get isLoggedIn(): boolean {
    return loginModule.isLoggedIn;
  }

  private logout() {
    loginModule.logout();
    this.$router.push({ name: "Login" });
  }
}
