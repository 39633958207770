
















import { Component, Vue } from "vue-property-decorator";
import { userModule } from "../store/modules/UserModule";
import UserListView from "./UserListView.vue";
import DebouncedTextField from "./DebouncedTextField.vue";
import { User } from "../api/types";

@Component({
  components: {
    UserListView,
    DebouncedTextField,
  },
})
export default class UserList extends Vue {
  filter = "";

  get loading(): boolean {
    return userModule.isLoadingList;
  }

  get error(): string {
    return userModule.listError;
  }

  get users(): User[] {
    return userModule.userList;
  }

  mounted() {
    userModule.loadList();
  }

  click(userId: string) {
    this.$router.push({ name: "User", params: { userId } });
  }

  addUser() {
    userModule.setAddUser(true);
  }
}
