




















import { Component, Vue } from "vue-property-decorator";
import { Item, GroupData } from "../api/types";
import GroupEditView from "./GroupEditView.vue";
import MessageDialog from "./MessageDialog.vue";
import { userModule } from "../store/modules/UserModule";

@Component({
  components: {
    GroupEditView,
    MessageDialog,
  },
})
export default class GroupEdit extends Vue {
  get isEdit(): boolean {
    return userModule.editGroup != null;
  }

  set isEdit(value: boolean) {
    if (!value) {
      userModule.cancelEdit();
    }
  }

  get group(): GroupData {
    return userModule.editGroup as GroupData;
  }

  get isChanged(): boolean {
    return userModule.isEditGroupChanged;
  }

  get isLoading(): boolean {
    return userModule.isEditLoading;
  }

  get editResult(): string {
    return userModule.editResultMessage;
  }

  get hasEditResult(): boolean {
    return userModule.editResultMessage != "";
  }

  set hasEditResult(value: boolean) {
    if (!value) {
      userModule.clearEditResultMessage();
    }
  }

  input(params: { index: number; item: Item }) {
    userModule.updateEditItem(params);
  }

  save() {
    userModule.saveEdit();
  }

  cancel() {
    userModule.cancelEdit();
  }
}
