










































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Group, Item } from "../api/types";
import ItemView from "./ItemView.vue";

@Component({
  components: {
    ItemView,
  },
})
export default class GroupEditView extends Vue {
  @Prop() group!: Group | undefined;

  @Prop() changed!: boolean;

  @Prop() isLoading!: boolean;

  get itemList(): Item[] {
    return this.group ? this.group.itemList || [] : [];
  }

  get hasItem(): boolean {
    return this.itemList.length > 0;
  }

  get label(): string {
    return this.group ? this.group.label : "";
  }

  @Emit("input")
  childChanged(index: number, item: Item) {
    return { index, item };
  }

  @Emit()
  cancel() {}

  @Emit()
  save() {}
}
