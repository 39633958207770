




































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { ItemDate } from "../api/types";
import ItemBaseView from "./ItemBaseView";

@Component
export default class ItemDateView extends Mixins(ItemBaseView) {
  @Prop() value!: ItemDate;

  private picker = false;

  @Watch("picker")
  private watchPicker(value: boolean) {
    if (value) {
      this.selectedDate = this.dateValue;
    }
  }

  private selectedDate = "";

  get date(): Date {
    return this.value.value ? new Date(this.value.value * 1000) : new Date();
  }

  get dateValue(): string {
    return (
      this.date.getFullYear() +
      "-" +
      ("0" + (this.date.getMonth() + 1)).substr(-2) +
      "-" +
      ("0" + this.date.getDate()).substr(-2)
    );
  }

  get dateString(): string {
    return !this.value.editable && !this.value.value
      ? "-"
      : this.date.getFullYear() +
          "/" +
          (this.date.getMonth() + 1) +
          "/" +
          this.date.getDate();
  }

  mounted() {
    if (this.isEdit && !this.value.value) {
      this.input(new Date().getTime() / 1000);
    }
  }

  inputDate() {
    this.picker = false;
    const [year, month, date] = this.selectedDate
      .split("-")
      .map((value) => Number(value));
    this.input(new Date(year, month - 1, date).getTime() / 1000);
  }

  formatDay(date: string) {
    return Number(date.split("-")[2]);
  }
}
