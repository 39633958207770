import BaseApi from "@/api/BaseApi";
import { UserDelete } from "./types";

class UserDeleteApi extends BaseApi<void> {
  constructor() {
    super("DELETE", "user");
  }

  public async call(user: UserDelete): Promise<void> {
    return this.callInternal({
      pathParams: [user.userId],
      data: { reason: user.reason },
    });
  }
}

export default new UserDeleteApi();
