































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { User, CategoryInfo } from "../api/types";
import CategoryView from "./CategoryView.vue";

@Component({
  components: {
    CategoryView,
  },
})
export default class UserInfoView extends Vue {
  @Prop() user!: User;

  @Prop() categoryList!: CategoryInfo[];

  @Prop() loading!: boolean;

  @Prop() error!: string;

  @Emit()
  load(category: CategoryInfo): CategoryInfo {
    return category;
  }

  @Emit()
  loadGroup(groupKey: string): string {
    return groupKey;
  }

  @Emit()
  edit(groupKey: string): string {
    return groupKey;
  }

  @Emit()
  detail(groupKey: string): string {
    return groupKey;
  }

  @Emit()
  editUser() {}

  @Emit()
  deleteUser() {}
}
