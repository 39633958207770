




















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { CategoryInfo } from "../api/types";
import GroupView from "./GroupView.vue";

@Component({
  components: {
    GroupView,
  },
})
export default class CategoryView extends Vue {
  @Prop() category!: CategoryInfo;

  @Prop() loading!: boolean;

  @Emit()
  edit(groupKey: string): string {
    return groupKey;
  }

  @Emit()
  load(): CategoryInfo {
    return this.category;
  }

  @Emit()
  loadGroup(groupKey: string): string {
    return groupKey;
  }

  @Emit()
  detail(groupKey: string): string {
    return groupKey;
  }
}
