import BaseApi from "@/api/BaseApi";
import { Item } from "./types";

class GroupPatchApi extends BaseApi<void> {
  constructor() {
    super("PATCH", "group");
  }

  public async call(
    userId: string,
    groupKey: string,
    list: Item[]
  ): Promise<void> {
    return this.callInternal({
      pathParams: [userId, groupKey],
      data: { list },
    });
  }
}

export default new GroupPatchApi();
