import BaseApi from "@/api/BaseApi";

class PwRequestApi extends BaseApi<void> {
  constructor() {
    super("POST", "pwrequest");
  }

  public async call(email: string): Promise<boolean> {
    return this.callInternal({ data: { email } }).then(() => true);
  }
}

export default new PwRequestApi();
