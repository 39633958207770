

















import { Component, Prop, Mixins } from "vue-property-decorator";
import { ItemRadio } from "../api/types";
import ItemBaseView from "./ItemBaseView";

@Component
export default class ItemRadioView extends Mixins(ItemBaseView) {
  @Prop() value!: ItemRadio;

  get horizontal(): boolean {
    return true;
  }
}
