import Vue from "vue";
import VueRouter, { RawLocation } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import User from "../views/User.vue";
import { loginModule } from "@/store/modules/LoginModule";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { noAuth: true },
  },
  {
    path: "/pwrequest",
    name: "PwRequest",
    component: () =>
      import(/* webpackChunkName: "pwrequest" */ "../views/PwRequest.vue"),
    meta: { noAuth: true },
  },
  {
    path: "/pwchange/:code",
    props: true,
    name: "PwChange",
    component: () =>
      import(/* webpackChunkName: "pwchange" */ "../views/PwChange.vue"),
    meta: { noAuth: true },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user/:userId/:groupKey?",
    props: true,
    name: "User",
    component: User,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const needsAuth = to.matched.some((record) => !record.meta.noAuth);
  const isLoggedIn = loginModule.isLoggedIn;
  if (needsAuth && !isLoggedIn) {
    loginModule.setLoginRedirect(to as RawLocation);
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
