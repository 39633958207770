
















































import { clone } from "@/common/utility";
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { User } from "../api/types";

type PointInfo = {
  points: string;
  lastPoint: number;
  rate: number;
  iconName: string;
  iconColor: string;
};

type DisplayUser = User & {
  displayBranch: string;
  secondaryPointInfo: PointInfo;
  kartePointInfo: PointInfo;
  status: string;
};

@Component
export default class UserListView extends Vue {
  @Prop() users!: User[];

  @Prop() loading!: boolean;

  @Prop() error!: string;

  private filter = "";

  private footerProps = {
    "items-per-page-text": "1ページの件数",
    "items-per-page-all-text": "全て",
    "items-per-page-options": [50, 100, 200, -1],
  };

  get isManager() {
    return this.users.some((user) => user.auth != "all");
  }

  get filterDescription() {
    return this.isManager
      ? "氏名、ふりがな、学徒番号、支部、担当者名で検索"
      : "氏名、ふりがな、学徒番号、支部で検索";
  }

  get headers() {
    const current = new Date().getMonth() + 1 + 12;
    const months =
      [4, 3, 2].map((i) => ((current - i) % 12) + 1).join("月/") + "月";
    const headers = [
      { text: "氏名", value: "name", groupable: false },
      { text: "支部・地域", value: "displayBranch" },
      {
        text: `地元参詣回数(${months})`,
        value: "secondaryPointInfo",
        groupable: false,
        filterable: false,
        sort: (a: PointInfo, b: PointInfo) => a.lastPoint - b.lastPoint,
      },
      {
        text: `活動入力数(${months})`,
        value: "kartePointInfo",
        groupable: false,
        filterable: false,
        sort: (a: PointInfo, b: PointInfo) => a.lastPoint - b.lastPoint,
      },
      { text: "ステータス (当月->翌月)", value: "status" },
    ];
    if (this.isManager) {
      headers.push({ text: "担当者", value: "mainStaffName" });
    }
    return headers;
  }

  get displayUsers(): DisplayUser[] {
    return this.users.map((user) => {
      const displayUser = clone(user) as DisplayUser;
      displayUser.displayBranch =
        user.branch + (displayUser.userId.startsWith("E") ? " (縁正)" : "");
      displayUser.secondaryPointInfo = this.getPointInfo(
        Number(displayUser.secondaryPoint0),
        Number(displayUser.secondaryPoint1),
        Number(displayUser.secondaryPoint2)
      );
      displayUser.kartePointInfo = this.getPointInfo(
        Number(displayUser.kartePoint0),
        Number(displayUser.kartePoint1),
        Number(displayUser.kartePoint2)
      );
      if (displayUser.secondaryPoint > 0) {
        displayUser.status = "A";
      } else if (displayUser.kartePoint > 0) {
        displayUser.status = "B";
      } else {
        displayUser.status = "C";
      }
      displayUser.status += " -> ";
      if (displayUser.nextSecondaryPoint > 0) {
        displayUser.status += "A";
      } else if (displayUser.nextKartePoint > 0) {
        displayUser.status += "B";
      } else {
        displayUser.status += "C";
      }
      if (displayUser.excludeTarget == 1) {
        displayUser.status += " (対象外)";
      }
      return displayUser;
    });
  }

  private getPointInfo(
    value0: number,
    value1: number,
    value2: number
  ): PointInfo {
    const points = `${value0} / ${value1} / ${value2}`;
    const rate = value0 + value1 == 0 ? 0 : value2 / (value0 + value1);
    let iconName, iconColor;

    if (value0 + value1 == 0) {
      iconName = "mdi-drag-horizontal-variant";
      iconColor = "yellow darken-2";
    } else if (rate > 0.65) {
      iconName = "mdi-arrow-top-right-thick";
      iconColor = "red darken-1";
    } else if (rate > 0.35) {
      iconName = "mdi-arrow-right-thick";
      iconColor = "green";
    } else {
      iconName = "mdi-arrow-bottom-right-thick";
      iconColor = "blue darken-2";
    }
    return { points, rate, iconName, iconColor, lastPoint: value2 };
  }

  // eslint-disable-next-line
  applyFilter(value: any, search: string | null, item: DisplayUser) {
    if (value == null || search == null || typeof value != "string") {
      return true;
    }
    if (search == item.userId) return true;
    if (this.containsIgnoreBlank(value, search)) {
      return true;
    }
    return this.containsIgnoreBlank(item.phonetic, search);
  }

  private containsIgnoreBlank(value: string, search: string): boolean {
    return value
      .split(/[\s\u3000]/g)
      .join("")
      .includes(search);
  }

  @Emit()
  click(user: User): string {
    return user.userId;
  }
}
