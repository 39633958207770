import Vue from "vue";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "../index";
import { logd } from "@/environment";
import {
  User,
  CategoryInfo,
  GroupData,
  Item,
  Group,
  emptyUser,
  UserDelete,
} from "@/api/types";
import UserListApi from "@/api/UserListApi";
import UserAddApi from "@/api/UserAddApi";
import UserDeleteApi from "@/api/UserDeleteApi";
import CategoryListGetApi from "@/api/CategoryListGetApi";
import CategoryGetApi from "@/api/CategoryGetApi";
import GroupPatchApi from "@/api/GroupPatchApi";
import GroupGetApi from "@/api/GroupGetApi";
import equal from "fast-deep-equal";
import { clone } from "@/common/utility";

const MESSAGE_NETWORK_ERROR =
  "インターネット接続を確認してください。接続していてもエラーが発生する場合、 tab-support@tstaff.jp にお問い合わせください。";

function networkError($message: string) {
  return $message + MESSAGE_NETWORK_ERROR;
}

@Module({ dynamic: true, store, name: "user", namespaced: true })
class UserModule extends VuexModule {
  public userList: User[] = [];

  public currentUserId = "";

  public isLoadingList = false;

  public listError = "";

  public isAdduser = false;

  public addUserError = "";

  public editUser: User | null = null;

  public deleteUserId = "";

  public deleteUserError = "";

  private categoryMap: { [key: string]: CategoryInfo } = {};

  public editGroup: GroupData | null = null;

  public isEditLoading = false;

  public editResultMessage = "";

  private detailGroupKey = "";

  private loadingSet: { [key: string]: 1 } = {};

  public infoError = "";

  get currentUser(): User {
    return (
      this.userList.find((user) => user.userId == this.currentUserId) ||
      emptyUser()
    );
  }

  get existsUser(): boolean {
    return this.currentUser.userId != "";
  }

  get displayCategoryMap(): { [key: string]: CategoryInfo } {
    const result = {};
    for (const [key, category] of Object.entries(this.categoryMap)) {
      Vue.set(result, key, category);
    }
    return result;
  }

  get categoryList(): CategoryInfo[] {
    return Object.values(this.displayCategoryMap);
  }

  get isLoadingInfo(): boolean {
    return Object.keys(this.loadingSet).length > 0;
  }

  get detailGroup(): Group | null {
    for (const category of Object.values(this.displayCategoryMap)) {
      for (const group of category.groupList) {
        if (group.groupKey == this.detailGroupKey) {
          return group;
        }
      }
    }
    return null;
  }

  get isEditGroupChanged(): boolean {
    const editGroup = this.editGroup;
    if (editGroup == null) return false;
    for (const category of Object.values(this.categoryMap)) {
      for (const group of category.groupList.values()) {
        if (group.groupKey == editGroup.groupKey) {
          return !equal(group, editGroup);
        }
      }
    }
    return true;
  }

  @Mutation
  private doSetCurrentUserId(userId: string) {
    this.categoryMap = {};
    this.currentUserId = userId;
  }

  @Mutation
  private startLoadList() {
    this.isLoadingList = true;
    this.listError = "";
  }

  @Mutation
  private finishLoadList(params: { userList?: User[]; error?: string }) {
    this.userList = params.userList || [];
    this.isLoadingList = false;
    this.listError = params.error || "";
  }

  @Mutation
  public setAddUser(isAdduser: boolean) {
    this.isAdduser = isAdduser;
    this.addUserError = "";
    this.editUser = null;
  }

  @Mutation
  public startEditUser(user: User) {
    this.isAdduser = true;
    this.addUserError = "";
    this.editUser = user;
  }

  @Mutation
  public setAddUserError(error: string) {
    this.addUserError = error;
  }

  @Mutation
  public setDeleteUser(userId: string) {
    this.deleteUserId = userId;
    this.deleteUserError = "";
  }

  @Mutation
  public setDeleteUserError(error: string) {
    this.deleteUserError = error;
  }

  @Mutation
  private startLoadInfo(key: string) {
    Vue.set(this.loadingSet, key, 1);
  }

  @Mutation
  private finishLoadInfo(key: string) {
    Vue.delete(this.loadingSet, key);
  }

  @Mutation
  private setInfoError(error: string) {
    this.infoError = error;
  }

  @Mutation
  private setCategory(list: CategoryInfo[]) {
    this.categoryMap = {};
    list.forEach((category) =>
      Vue.set(this.categoryMap, category.categoryKey, category)
    );
  }

  @Mutation
  private setDetailGroupKey(groupKey: string) {
    this.detailGroupKey = groupKey;
  }

  @Mutation
  private addGroupData(params: { categoryKey: string; list: GroupData[] }) {
    if (this.categoryMap[params.categoryKey]) {
      this.categoryMap[params.categoryKey].groupList = params.list;
    }
  }

  @Action({})
  public async setCurrentUserId(userId: string) {
    logd(() => `setCurrentUserId ${userId}`);
    const changed = userId != this.currentUserId;
    this.doSetCurrentUserId(userId);
    if (!this.existsUser) {
      this.loadList();
    }
    if (changed) {
      this.reload();
    }
  }

  @Action({})
  public async loadList() {
    logd(() => `load`);
    this.startLoadList();
    try {
      const userList = await UserListApi.call();
      this.finishLoadList({ userList });
    } catch {
      this.finishLoadList({
        error: networkError("対象者一覧取得に失敗しました。"),
      });
    }
  }

  @Action({})
  public async addUser(user: User) {
    logd(() => `addUser`);
    logd(() => user);
    try {
      const oldUserId = this.editUser ? this.editUser.userId : "";
      await UserAddApi.call(user, oldUserId);
      if (this.editUser) {
        this.setCurrentUserId(user.userId);
      }
      this.loadList();
      this.setAddUser(false);
    } catch (e) {
      this.setAddUserError(e.error || "エラーが発生しました");
    }
  }

  @Action({})
  public async deleteUser(params: UserDelete) {
    logd(() => `deleteUser`);
    logd(() => params.userId);
    try {
      await UserDeleteApi.call(params);
      this.loadList();
      this.setDeleteUser("");
    } catch (e) {
      this.setDeleteUserError(e.error || "エラーが発生しました");
    }
  }

  @Action({})
  private async reload() {
    logd(() => `reload`);
    this.startLoadInfo("reload");
    this.setCategory([]);
    try {
      const categoryList = await CategoryListGetApi.call();
      this.setCategory(categoryList);
      this.setInfoError("");
    } catch {
      this.setInfoError(networkError("情報取得に失敗しました。"));
    }
    this.clearDetailgroup();
    this.finishLoadInfo("reload");
  }

  @Action({})
  public async loadCategoryData(categoryKey: string) {
    logd(() => `load ${categoryKey}`);
    this.startLoadInfo(`loadCategoryData ${categoryKey}`);
    try {
      const list = await CategoryGetApi.call(this.currentUserId, categoryKey);
      this.addGroupData({ categoryKey, list });
      this.setInfoError("");
    } catch {
      this.setInfoError(networkError("情報取得に失敗しました。"));
    }
    this.finishLoadInfo(`loadCategoryData ${categoryKey}`);
  }

  @Action({})
  public async loadGroupData(params: { groupKey: string; all?: boolean }) {
    const userId = this.currentUserId;
    const groupKey = params.groupKey;
    const all = params.all || false;
    logd(() => `load group ${groupKey} ${all}`);
    let targetGroup: Group | GroupData | null = null;
    for (const category of Object.values(this.displayCategoryMap)) {
      for (const group of category.groupList) {
        if (group.groupKey == groupKey) {
          targetGroup = clone(group);
          break;
        }
      }
    }
    if (targetGroup) {
      if (!all && "itemList" in targetGroup) {
        Vue.delete(targetGroup, "itemList");
        this.updateGroup(targetGroup);
      } else {
        try {
          this.startLoadInfo(`loadGroupData ${groupKey}`);
          targetGroup.itemList = await GroupGetApi.call(userId, groupKey, all);
          this.updateGroup(targetGroup);
          this.setInfoError("");
        } catch {
          this.setInfoError(networkError("情報取得に失敗しました。"));
        }
        this.finishLoadInfo(`loadGroupData ${groupKey}`);
      }
    }
  }

  @Action({})
  public startEdit(groupKey: string) {
    for (const category of Object.values(this.displayCategoryMap)) {
      for (const group of category.groupList) {
        if (group.itemList && group.groupKey == groupKey) {
          this.setEditGroup({ itemList: [], ...clone(group) });
        }
      }
    }
  }

  @Mutation
  private setEditGroup(editGroup: GroupData) {
    this.editGroup = editGroup;
  }

  @Mutation
  public updateEditItem(params: { index: number; item: Item }) {
    if (this.editGroup) {
      Vue.set(this.editGroup.itemList, params.index, params.item);
    }
  }

  @Mutation
  public cancelEdit() {
    this.editGroup = null;
  }

  @Mutation
  private setEditStatus(params: { isLoading: boolean; message: string }) {
    this.isEditLoading = params.isLoading;
    this.editResultMessage = params.message;
  }

  @Mutation
  public clearEditResultMessage() {
    this.editResultMessage = "";
  }

  @Mutation
  private updateGroup(newGroup: Group) {
    for (const [categoryKey, category] of Object.entries(this.categoryMap)) {
      for (const [index, group] of category.groupList.entries()) {
        if (group.groupKey == newGroup.groupKey) {
          Vue.set(
            this.categoryMap[categoryKey].groupList,
            index,
            clone(newGroup)
          );
        }
      }
    }
  }

  @Action({})
  public async saveEdit() {
    logd(() => "saveEdit");
    logd(() => this.editGroup);
    if (this.editGroup) {
      const userId = this.currentUserId;
      const group = this.editGroup;
      this.setEditStatus({ isLoading: true, message: "" });
      try {
        await GroupPatchApi.call(userId, group.groupKey, group.itemList);
        group.itemList = await GroupGetApi.call(userId, group.groupKey, false);
        this.setEditStatus({ isLoading: false, message: "保存しました" });
        this.updateGroup(group);
      } catch {
        this.setEditStatus({
          isLoading: false,
          message: networkError("データ更新に失敗しました。"),
        });
      }
    }
  }

  @Action({})
  public async loadDetailGroup(groupKey: string) {
    logd(() => `loadDetailGroup ${this.currentUserId} ${groupKey}`);
    if (groupKey) {
      this.setDetailGroupKey(groupKey);
      if (!this.detailGroup) {
        await this.reload();
      }
      await this.loadGroupData({ groupKey, all: true });
    } else {
      this.clearDetailgroup();
    }
  }

  @Action({})
  private clearDetailgroup() {
    if (this.detailGroup) {
      const newGroup: Group = {
        groupKey: this.detailGroupKey,
        label: this.detailGroup.label,
        hasDetail: this.detailGroup.hasDetail,
      };
      this.updateGroup(newGroup);
      this.setDetailGroupKey("");
    }
  }
}

export const userModule = getModule(UserModule);
