import BaseApi from "@/api/BaseApi";
import { CategoryInfo } from "./types";

class CategoryListGetApi extends BaseApi<{ list: CategoryInfo[] }> {
  constructor() {
    super("GET", "categorylist");
  }

  public async call(): Promise<CategoryInfo[]> {
    return this.callInternal().then((result) => result.list);
  }
}

export default new CategoryListGetApi();
