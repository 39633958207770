









import { Component, Vue } from "vue-property-decorator";
import { loginModule } from "../store/modules/LoginModule";
import LoginFormView, { LoginInfo } from "./LoginFormView.vue";

@Component({
  components: {
    LoginFormView,
  },
})
export default class LoginForm extends Vue {
  get loading(): boolean {
    return loginModule.isLoading;
  }

  get error(): string {
    return loginModule.error;
  }

  login(params: LoginInfo) {
    loginModule.login(params);
  }

  changePassword() {
    this.$router.push({ name: "PwRequest" });
  }
}
