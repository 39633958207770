










import { Component, Vue, Watch } from "vue-property-decorator";
import { loginModule } from "../store/modules/LoginModule";
import LoginForm from "@/components/LoginForm.vue";

@Component({
  components: {
    LoginForm,
  },
})
export default class Login extends Vue {
  get isLoggedIn(): boolean {
    return loginModule.isLoggedIn;
  }

  @Watch("isLoggedIn")
  watchIsLoggedIn(newValue: boolean) {
    if (newValue) {
      const loginRedirect = loginModule.loginRedirect;
      if (loginRedirect) {
        this.$router.push(loginRedirect);
        loginModule.setLoginRedirect(null);
      } else {
        this.$router.push({ name: "Home" });
      }
    }
  }
}
