











import { Component, Vue } from "vue-property-decorator";
import UserDeleteView from "./UserDeleteView.vue";
import { userModule } from "../store/modules/UserModule";
import { UserDelete } from "@/api/types";

@Component({
  components: {
    UserDeleteView,
  },
})
export default class UserAdd extends Vue {
  get isDeleteUser(): boolean {
    return userModule.deleteUserId != "";
  }

  get error(): string {
    return userModule.deleteUserError;
  }

  get userId(): string {
    return userModule.deleteUserId;
  }

  save(user: UserDelete) {
    userModule.deleteUser(user);
  }

  cancel() {
    userModule.setDeleteUser("");
  }
}
