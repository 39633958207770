import BaseApi from "@/api/BaseApi";
import { GroupData } from "./types";

class CategoryGetApi extends BaseApi<{ list: GroupData[] }> {
  constructor() {
    super("GET", "category");
  }

  public async call(userId: string, categoryKey: string): Promise<GroupData[]> {
    return this.callInternal({ pathParams: [userId, categoryKey] }).then(
      (result) => result.list
    );
  }
}

export default new CategoryGetApi();
