
















import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class MessageDialog extends Vue {
  @Prop() value!: boolean;

  @Prop() title!: string;

  @Prop() message!: string;

  get open(): boolean {
    return this.value;
  }

  set open(value: boolean) {
    this.input();
  }

  @Emit()
  input() {
    return false;
  }
}
