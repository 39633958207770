

































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Item } from "../api/types";
import ItemTextView from "./ItemTextView.vue";
import ItemRadioView from "./ItemRadioView.vue";
import ItemCheckboxView from "./ItemCheckboxView.vue";
import ItemDateView from "./ItemDateView.vue";
import ItemSetView from "./ItemSetView.vue";

@Component({
  components: {
    ItemTextView,
    ItemRadioView,
    ItemCheckboxView,
    ItemDateView,
    ItemSetView,
  },
})
export default class ItemView extends Vue {
  @Prop() value!: Item;

  @Prop() edit!: boolean;

  @Emit()
  input() {}
}
