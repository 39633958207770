












































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

export type LoginInfo = {
  email: string;
  password: string;
};

@Component
export default class LoginFormView extends Vue {
  @Prop() loading!: boolean;

  @Prop() error!: string;

  private email = "";

  private password = "";

  get disabled() {
    return this.email == "" || this.password == "";
  }

  @Emit()
  login(): LoginInfo {
    return { email: this.email, password: this.password };
  }

  @Emit("change-password")
  changePassword() {}
}
