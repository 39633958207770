



































import { UserDelete } from "@/api/types";
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";

@Component
export default class UserDeleteView extends Vue {
  @Prop() error!: string;

  @Prop() userId!: string;

  private selectedReason = "退会";

  private inputReason = "";

  get canInput() {
    return this.selectedReason == "その他";
  }

  get valid() {
    return !this.canInput || this.inputReason;
  }

  @Emit()
  save(): UserDelete {
    return {
      userId: this.userId,
      reason: this.canInput ? this.inputReason : this.selectedReason,
    };
  }

  @Emit()
  cancel() {}
}
