











import { Component, Vue } from "vue-property-decorator";
import Navigation from "./components/Navigation.vue";
import UserAdd from "./components/UserAdd.vue";
import UserDelete from "./components/UserDelete.vue";

@Component({ components: { Navigation, UserAdd, UserDelete } })
export default class App extends Vue {}
