import BaseApi from "@/api/BaseApi";

class PwChangeApi extends BaseApi<void> {
  constructor() {
    super("POST", "pwchange");
  }

  public async call(code: string, password = ""): Promise<boolean> {
    return this.callInternal({ data: { code, password } }).then(() => true);
  }
}

export default new PwChangeApi();
