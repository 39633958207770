import BaseApi from "@/api/BaseApi";

class LoginApi extends BaseApi<void> {
  constructor() {
    super("POST", "login");
  }

  public async call(email: string, password: string): Promise<boolean> {
    return this.callInternal({ data: { email, password } }).then(() => true);
  }
}

export default new LoginApi();
