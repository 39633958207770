











import { Component, Vue } from "vue-property-decorator";
import { User } from "../api/types";
import UserEditView from "./UserEditView.vue";
import { userModule } from "../store/modules/UserModule";

@Component({
  components: {
    UserEditView,
  },
})
export default class UserAdd extends Vue {
  get isAddUser(): boolean {
    return userModule.isAdduser;
  }

  get error(): string {
    return userModule.addUserError;
  }

  get editUser(): User | null {
    return userModule.editUser;
  }

  save(user: User) {
    userModule.addUser(user);
  }

  cancel() {
    userModule.setAddUser(false);
  }
}
