















import { Component, Vue } from "vue-property-decorator";
import { userModule } from "../store/modules/UserModule";
import UserInfoView from "./UserInfoView.vue";
import { User, CategoryInfo } from "../api/types";

@Component({
  components: {
    UserInfoView,
  },
})
export default class UserInfo extends Vue {
  get userId(): string {
    return userModule.currentUserId;
  }

  get user(): User {
    return userModule.currentUser;
  }

  get loading(): boolean {
    return userModule.isLoadingInfo;
  }

  get error(): string {
    return userModule.infoError;
  }

  get categoryList(): CategoryInfo[] {
    return userModule.categoryList;
  }

  load(category: CategoryInfo) {
    userModule.loadCategoryData(category.categoryKey);
  }

  loadGroup(groupKey: string) {
    userModule.loadGroupData({ groupKey });
  }

  edit(groupKey: string) {
    userModule.startEdit(groupKey);
  }

  detail(groupKey: string) {
    this.$router.push({
      name: "User",
      params: { userId: this.userId, groupKey },
    });
  }

  editUser() {
    userModule.startEditUser(this.user);
  }

  deleteUser() {
    userModule.setDeleteUser(this.userId);
  }
}
