import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "../index";
import { logd } from "@/environment";
import { RawLocation } from "vue-router";
import LoginApi from "@/api/LoginApi";
import LogoutApi from "@/api/LogoutApi";
import PwRequestApi from "@/api/PwRequestApi";
import PwChangeApi from "@/api/PwChangeApi";

export type PwChangeState =
  | "none"
  | "loading"
  | "requested"
  | "verified"
  | "changed"
  | "error";

@Module({ dynamic: true, store, name: "login", namespaced: true })
class LoginModule extends VuexModule {
  public isLoggedIn = false;

  public isLoading = false;

  public error = "";

  public loginRedirect: RawLocation | null = null;

  public pwChangeState: PwChangeState = "none";

  @Mutation
  private setLoggedIn(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
  }

  @Mutation
  private startLogin() {
    this.isLoading = true;
    this.error = "";
  }

  @Mutation
  private finishLogin(error: string) {
    this.isLoggedIn = error == "";
    this.isLoading = false;
    this.error = error;
  }

  @Mutation
  public setLoginRedirect(loginRedirect: RawLocation | null) {
    this.loginRedirect = loginRedirect
      ? Object.assign({}, loginRedirect)
      : null;
  }

  @Mutation
  public setPwChangeState(state: PwChangeState) {
    this.pwChangeState = state;
  }

  @Action({})
  public async login(params: { email: string; password: string }) {
    logd(() => `login ${params.email}, ${params.password}`);
    try {
      this.startLogin();
      const result = await LoginApi.call(params.email, params.password);
      this.finishLogin(result ? "" : "ログインに失敗しました。");
    } catch (e) {
      console.log(e);
      this.finishLogin("ログインに失敗しました。");
    }
  }

  @Action({})
  public async logout() {
    logd(() => `logout`);
    this.setLoggedIn(false);
    await LogoutApi.call();
  }

  @Action({})
  public async requestPassword(email: string) {
    logd(() => `pwrequest ${email}`);
    try {
      this.setPwChangeState("loading");
      await PwRequestApi.call(email);
      this.setPwChangeState("requested");
    } catch {
      this.setPwChangeState("error");
    }
  }

  @Action({})
  public async changePassword(params: { code: string; password?: string }) {
    logd(() => `pwchange ${params.code} ${params.password}`);
    try {
      this.setPwChangeState("loading");
      await PwChangeApi.call(params.code, params.password || "");
      this.setPwChangeState(params.password ? "changed" : "verified");
    } catch {
      this.setPwChangeState("error");
    }
  }
}

export const loginModule = getModule(LoginModule);
