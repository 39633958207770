



















import { Component, Prop, Mixins } from "vue-property-decorator";
import { ItemText } from "../api/types";
import ItemBaseView from "./ItemBaseView";

@Component
export default class ItemTextView extends Mixins(ItemBaseView) {
  @Prop() value!: ItemText;

  get isTextArea(): boolean {
    return this.value.size >= 40;
  }
}
