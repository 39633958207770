




import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { debounce } from "ts-debounce";

@Component
export default class DebouncedTextField extends Vue {
  @Prop() value!: string;

  @Prop() description!: string;

  @Prop() waitTime?: number;

  rawValue = "";

  get onChange(): (value: string) => void {
    return debounce(this.input, this.waitTime || 500);
  }

  @Watch("rawValue")
  watchRawValue(value: string) {
    this.onChange(value);
  }

  @Emit()
  input(value: string): string {
    return value;
  }
}
